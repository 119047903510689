/* eslint-disable */
// @ts-nocheck
import type { Pendo } from './helpers'

declare global {
  var pendo: Pendo
}

export const initPendo = (apiKey: string) => {
  ;((p, e, n, d, o) => {
    // biome-ignore lint: code snippet from pendo
    var v, w, x, y, z
    // biome-ignore lint: code snippet from pendo
    o = p[d] = p[d] || {}
    o._q = o._q || []
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
    for (w = 0, x = v.length; w < x; ++w)
      ((m) => {
        o[m] =
          o[m] ||
          (() => {
            o._q[m === v[0] ? 'unshift' : 'push'](
              // biome-ignore lint/style/noArguments: snippet from pendo
              [m].concat([].slice.call(arguments, 0)),
            )
          })
      })(v[w])
    y = e.createElement(n)
    y.async = !0
    y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`
    z = e.getElementsByTagName(n)[0]
    z.parentNode.insertBefore(y, z)
  })(window, document, 'script', 'pendo')
}
